<template>
  <router-link
    :to="{ name: props.routeTo }"
    class="
      relative
      p-4
      lg:p-6
      transition-all
      duration-300
      rounded-2xl
      shadow
      pt-7
      lg:pt-9
      sm:hover:shadow-md sm:hover:-translate-y-1.5
      bg-gradient-to-r
    "
    :class="{
      'from-amber-100': props.colorPri === 'amber',
      'from-sky-100': props.colorPri === 'sky',
      'from-green-100': props.colorPri === 'green',
      'from-rose-100': props.colorPri === 'rose',
      'to-red-200': props.colorSec === 'red',
      'to-purple-200': props.colorSec === 'purple',
      'to-lime-200': props.colorSec === 'lime',
      'to-pink-200': props.colorSec === 'pink',
    }"
  >
    <div
      class="
        absolute
        top-0
        p-2
        -translate-x-1/2 -translate-y-1/2
        bg-opacity-50
        rounded-full
        shadow-sm
        bg-blue-gray-200
        left-1/2
        backdrop-blur-sm
      "
    >
      <slot name="icon" />
    </div>
    <h2 class="mb-2 text-lg font-bold lg:text-xl text-blue-gray-800">
      {{ props.cardHeader }}
    </h2>
    <p class="text-sm leading-5 lg:text-base">
      {{ props.cardDesc }}
    </p>
  </router-link>
</template>

<script setup>
const props = defineProps({
  routeTo: {
    type: String,
    required: true,
  },
  colorPri: {
    type: String,
    required: true,
  },
  colorSec: {
    type: String,
    required: true,
  },
  cardHeader: {
    type: String,
    requred: true,
  },
  cardDesc: {
    type: String,
    requred: true,
  },
});
</script>
