<template>
  <header
    class="
      fixed
      top-0
      z-50
      flex
      content-center
      justify-between
      w-full
      px-2
      py-3
      tracking-wide
      transition
      duration-300
      bg-opacity-70
    "
    :class="{
      'text-blue-gray-300': headerInView,
      'bg-blue-gray-50 shadow': !headerInView,
    }"
    :style="[!headerInView ? 'backdrop-filter: blur(20px)' : '']"
  >
    <!-- Logo and Title -->
    <router-link
      class="flex items-center gap-1 transition duration-300"
      :class="{
        'sm:hover:text-sky-200': headerInView,
        'sm:hover:text-sky-800': !headerInView,
      }"
      active-class="no-active"
      :to="{ name: 'home' }"
    >
      <svg
        class="hidden w-4 h-4 sm:inline-block md:w-5 md:h-5"
        viewBox="0 0 499 493"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="261.638"
          y="247"
          width="48"
          height="255.276"
          rx="24"
          transform="rotate(30 261.638 247)"
          fill="currentColor"
        />
        <rect
          x="281.363"
          y="46"
          width="48"
          height="194.726"
          rx="24"
          transform="rotate(30 281.363 46)"
          fill="currentColor"
        />
        <rect
          x="303.387"
          y="283.56"
          width="48"
          height="134.68"
          rx="24"
          transform="rotate(143 303.387 283.56)"
          fill="currentColor"
        />
        <path
          d="M324.627 16.25L324.627 124.503L230.877 70.3766L324.627 16.25Z"
          fill="currentColor"
        />
        <path
          d="M339.615 132.427C331.095 122.274 332.42 107.136 342.574 98.6155C352.727 90.0954 367.866 91.4199 376.386 101.574L492.73 240.228C501.25 250.381 499.926 265.52 489.772 274.04C479.618 282.56 464.48 281.235 455.96 271.082L339.615 132.427Z"
          fill="currentColor"
        />
        <path
          d="M455.96 241.289C464.48 231.135 479.618 229.811 489.772 238.331C499.926 246.851 501.25 261.989 492.73 272.143L376.386 410.797C367.866 420.951 352.727 422.275 342.574 413.755C332.42 405.235 331.095 390.097 339.615 379.943L455.96 241.289Z"
          fill="currentColor"
        />
        <path
          d="M158.73 379.943C167.25 390.097 165.926 405.235 155.772 413.755C145.618 422.275 130.48 420.951 121.96 410.797L5.61551 272.143C-2.90453 261.989 -1.58012 246.851 8.57367 238.331C18.7275 229.811 33.8656 231.135 42.3856 241.289L158.73 379.943Z"
          fill="currentColor"
        />
        <path
          d="M42.3857 271.082C33.8656 281.235 18.7275 282.56 8.57368 274.04C-1.58011 265.52 -2.90453 250.381 5.61551 240.228L121.96 101.574C130.48 91.4199 145.618 90.0955 155.772 98.6155C165.926 107.136 167.25 122.274 158.73 132.427L42.3857 271.082Z"
          fill="currentColor"
        />
      </svg>
      <p
        class="
          text-xs
          font-bold
          leading-3
          sm:text-sm
          md:text-base
          xl:text-lg
          md:leading-3
          xl:leading-4
        "
      >
        SGUniStats
      </p>
    </router-link>

    <!-- Navigation Links -->
    <nav class="self-center">
      <ul
        class="
          flex
          items-center
          gap-2
          text-xs
          font-semibold
          sm:gap-3
          md:gap-4
          xl:gap-6
          md:text-sm
          xl:text-base
        "
      >
        <li>
          <router-link
            :to="{ name: 'calculator' }"
            class="transition duration-300 flex items-center gap-0.5 xl:gap-1"
            :class="{
              'sm:hover:text-sky-200': headerInView,
              'sm:hover:text-sky-700': !headerInView,
            }"
            :active-class="activeClass"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="hidden w-4 h-4 md:w-5 xl:w-6 md:h-5 xl:h-6 sm:inline-block"
              fill="currentColor"
              viewBox="0 0 256 256"
            >
              <rect width="256" height="256" fill="none"></rect>
              <path
                d="M200,24H56A16.01833,16.01833,0,0,0,40,40V216a16.01833,16.01833,0,0,0,16,16H200a16.01833,16.01833,0,0,0,16-16V40A16.01833,16.01833,0,0,0,200,24ZM88,199.99219a12,12,0,1,1,12-12A12.01375,12.01375,0,0,1,88,199.99219Zm0-40a12,12,0,1,1,12-12A12.01375,12.01375,0,0,1,88,159.99219Zm40,40a12,12,0,1,1,12-12A12.01375,12.01375,0,0,1,128,199.99219Zm0-40a12,12,0,1,1,12-12A12.01375,12.01375,0,0,1,128,159.99219Zm40,40a12,12,0,1,1,12-12A12.01375,12.01375,0,0,1,168,199.99219Zm0-40a12,12,0,1,1,12-12A12.01375,12.01375,0,0,1,168,159.99219Zm16-56a7.99977,7.99977,0,0,1-8,8H80a7.99977,7.99977,0,0,1-8-8v-40a7.99977,7.99977,0,0,1,8-8h96a7.99977,7.99977,0,0,1,8,8Z"
              ></path>
            </svg>
            <p>Calculator</p>
          </router-link>
        </li>
        <li>
          <router-link
            :to="{ name: 'prospects' }"
            class="transition duration-300 flex items-center gap-0.5 xl:gap-1"
            :class="{
              'sm:hover:text-sky-200': headerInView,
              'sm:hover:text-sky-700': !headerInView,
            }"
            :active-class="activeClass"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="hidden w-4 h-4 md:w-5 xl:w-6 md:h-5 xl:h-6 sm:inline-block"
              fill="currentColor"
              viewBox="0 0 256 256"
            >
              <rect width="256" height="256" fill="none"></rect>
              <line
                x1="128"
                y1="24"
                x2="128"
                y2="232"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="24"
              ></line>
              <path
                d="M184,88a40,40,0,0,0-40-40H108a40,40,0,0,0,0,80h44a40,40,0,0,1,0,80H104a40,40,0,0,1-40-40"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="24"
              ></path>
            </svg>
            <p>Prospects</p></router-link
          >
        </li>
        <li>
          <router-link
            :to="{ name: 'courses' }"
            class="transition duration-300 flex items-center gap-0.5 xl:gap-1"
            :class="{
              'sm:hover:text-sky-200': headerInView,
              'sm:hover:text-sky-700': !headerInView,
            }"
            :active-class="activeClass"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="hidden w-4 h-4 md:w-5 xl:w-6 md:h-5 xl:h-6 sm:inline-block"
              fill="currentColor"
              viewBox="0 0 256 256"
            >
              <rect width="256" height="256" fill="none"></rect>
              <path
                d="M80,64a8.00008,8.00008,0,0,1,8-8H216a8,8,0,0,1,0,16H88A8.00008,8.00008,0,0,1,80,64Zm136,56H88.00586a8,8,0,1,0,0,16H216a8,8,0,0,0,0-16Zm0,64H88.00586a8,8,0,1,0,0,16H216a8,8,0,0,0,0-16ZM44,116a12,12,0,1,0,12,12A12.01343,12.01343,0,0,0,44,116Zm0-64A12,12,0,1,0,56,64,12.01343,12.01343,0,0,0,44,52Zm0,128a12,12,0,1,0,12,12A12.01343,12.01343,0,0,0,44,180Z"
              ></path>
            </svg>
            <p>Courses</p></router-link
          >
        </li>
        <li>
          <!-- Collapsible Menu -->
          <the-nav-menu :header-in-view="headerInView" />
        </li>
      </ul>
    </nav>
  </header>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

import TheNavMenu from "@/components/TheNavMenu.vue";

const store = useStore();
const headerInView = computed(() => store.state.headerInView);
const activeClass = computed(() =>
  headerInView.value ? "text-sky-200 underline" : "text-sky-700 underline"
);
</script>
