<template>
  <section
    ref="heading"
    class="relative w-full bg-center bg-cover"
    :class="{
      'bg-library-default md:bg-library-md lg:bg-library-lg':
        props.backgroundImage === 'library',
      'bg-study-default md:bg-study-md lg:bg-study-lg':
        props.backgroundImage === 'study',
      'bg-city-default md:bg-city-md lg:bg-city-lg':
        props.backgroundImage === 'city',
      'bg-graduation-default md:bg-graduation-md lg:bg-graduation-lg':
        props.backgroundImage === 'graduation',
    }"
  >
    <div
      class="container px-3 py-20 mx-auto sm:px-0 md:py-24 xl:py-28 2xl:py-32"
    >
      <slot name="header"></slot>
    </div>
    <div
      class="
        absolute
        bottom-0
        z-10
        w-full
        h-6
        rounded-t-full
        bg-blue-gray-50
        shadow-t-xl
      "
    />
  </section>
</template>

<script setup>
import { ref, onMounted, onUnmounted, nextTick } from "vue";
import { useStore } from "vuex";

const props = defineProps({
  backgroundImage: {
    type: String,
    required: true,
  },
});

let observer;
const heading = ref(null);
const store = useStore();
const updateHeader = (headerInView) =>
  store.dispatch("updateHeader", headerInView);

onMounted(() => {
  nextTick(() => {
    // Code that will run after view rendered
    observer = new IntersectionObserver(
      ([entry]) => {
        if (entry) updateHeader(entry.isIntersecting);
      },
      { threshold: 0, root: null, rootMargin: "-52px" }
    );
    observer.observe(heading.value);
  });
});

onUnmounted(() => {
  observer.disconnect();
});
</script>
