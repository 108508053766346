<template>
  <section
    class="container px-3 pb-16 mx-auto sm:px-0"
    :class="[homePage ? 'pt-4 sm:pt-8' : 'pt-16']"
  >
    <h2 class="mb-10 text-2xl font-bold text-blue-gray-800">
      {{ props.desc }}
    </h2>
    <div
      class="grid gap-8 auto-rows-fr grid-cols-1"
      :class="[
        props.hiddenCards.length === 0 ? 'md:grid-cols-2' : 'md:grid-cols-3',
      ]"
    >
      <!-- Calculator -->
      <app-cards-item
        route-to="calculator"
        color-pri="amber"
        color-sec="red"
        card-header="Rank Point Calculator"
        card-desc="Calculate your A-level rank points and estimate the number of eligible courses for based on the Indicative Grade Profile (IGP)."
        v-if="props.hiddenCards.indexOf('calculator') === -1"
      >
        <template #icon>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-8 h-8 lg:w-9 lg:h-9"
            fill="#1e293b"
            viewBox="0 0 256 256"
          >
            <rect width="256" height="256" fill="none"></rect>
            <rect
              x="32"
              y="48"
              width="192"
              height="160"
              rx="8"
              transform="translate(256) rotate(90)"
              stroke-width="24"
              stroke="#1e293b"
              stroke-linecap="round"
              stroke-linejoin="round"
              fill="none"
            ></rect>
            <line
              x1="92"
              y1="76"
              x2="164"
              y2="76"
              fill="none"
              stroke="#1e293b"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="24"
            ></line>
            <circle cx="104" cy="128" r="16"></circle>
            <circle cx="152" cy="128" r="16"></circle>
            <circle cx="104" cy="176" r="16"></circle>
            <circle cx="152" cy="176" r="16"></circle>
          </svg>
        </template>
      </app-cards-item>

      <!-- Prospects -->
      <app-cards-item
        route-to="prospects"
        color-pri="sky"
        color-sec="purple"
        card-header="Employment Trends"
        card-desc="Compare job prospects between university majors with Graduate Employment Survey (GES) results from median pay to employment rate."
        v-if="props.hiddenCards.indexOf('prospects') === -1"
      >
        <template #icon>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-8 h-8 md:w-9 md:h-9"
            fill="#1e293b"
            viewBox="0 0 256 256"
          >
            <rect width="256" height="256" fill="none"></rect>
            <line
              x1="128"
              y1="24"
              x2="128"
              y2="232"
              fill="none"
              stroke="#1e293b"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="24"
            ></line>
            <path
              d="M184,88a40,40,0,0,0-40-40H108a40,40,0,0,0,0,80h44a40,40,0,0,1,0,80H104a40,40,0,0,1-40-40"
              fill="none"
              stroke="#1e293b"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="24"
            ></path>
          </svg>
        </template>
      </app-cards-item>

      <!-- Courses -->
      <app-cards-item
        route-to="courses"
        color-pri="green"
        color-sec="lime"
        card-header="Course Directory"
        card-desc="Learn more about each course in full detail, from the median starting salary for fresh graduates to the estimated cut off point."
        v-if="props.hiddenCards.indexOf('courses') === -1"
      >
        <template #icon>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-8 h-8 md:w-9 md:h-9"
            fill="#1e293b"
            viewBox="0 0 256 256"
          >
            <rect width="256" height="256" fill="none"></rect>
            <line
              x1="88"
              y1="64"
              x2="216"
              y2="64"
              fill="none"
              stroke="#1e293b"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="24"
            ></line>
            <line
              x1="88.00614"
              y1="128"
              x2="216"
              y2="128"
              fill="none"
              stroke="#1e293b"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="24"
            ></line>
            <line
              x1="88.00614"
              y1="192"
              x2="216"
              y2="192"
              fill="none"
              stroke="#1e293b"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="24"
            ></line>
            <circle cx="44" cy="128" r="16"></circle>
            <circle cx="44" cy="64" r="16"></circle>
            <circle cx="44" cy="192" r="16"></circle>
          </svg>
        </template>
      </app-cards-item>

      <!-- Poly IGP Comparison -->
      <app-cards-item
        route-to="poly"
        color-pri="rose"
        color-sec="pink"
        card-header="Poly IGP Comparison"
        card-desc="Compare your Poly GPA to the latest Indicative Grade Profiles (IGPs) and find out your chances of university admission."
        v-if="props.hiddenCards.indexOf('poly') === -1"
      >
        <template #icon>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-8 h-8 md:w-9 md:h-9"
            fill="#1e293b"
            viewBox="0 0 256 256"
          >
            <rect width="256" height="256" fill="none"></rect>
            <line
              x1="32"
              y1="64"
              x2="32"
              y2="144"
              fill="none"
              stroke="#000000"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="24"
            ></line>
            <path
              d="M54.2,216a88.1,88.1,0,0,1,147.6,0"
              fill="none"
              stroke="#000000"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="24"
            ></path>
            <polygon
              points="224 64 128 96 32 64 128 32 224 64"
              fill="none"
              stroke="#000000"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="24"
            ></polygon>
            <path
              d="M169.3,82.2a56,56,0,1,1-82.6,0"
              fill="none"
              stroke="#000000"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="24"
            ></path>
          </svg>
        </template>
      </app-cards-item>
    </div>
  </section>
</template>

<script setup>
import AppCardsItem from "@/components/AppCardsItem.vue";

const props = defineProps({
  hiddenCards: {
    type: Array,
    default() {
      return [];
    },
  },
  desc: {
    type: String,
    required: true,
  },
  homePage: {
    type: Boolean,
    default() {
      return false;
    },
  },
});
</script>
