<template>
  <teleport to="head">
    <!-- Title & Description -->
    <title>{{ $route.meta.title }}</title>
    <meta name="description" :content="$route.meta.desc" />

    <!-- Excluding from crawler -->
    <meta v-if="$route.meta.noIndex" name="robots" content="noindex" />

    <!-- Open Graph Tags -->
    <meta property="og:title" :content="$route.meta.title" />
    <meta name="og:url" :content="$route.fullPath" />
    <meta property="og:description" :content="$route.meta.desc" />
    <meta property="og:image" :content="$route.meta.image" />
    <meta property="og:type" content="website" />

    <!-- Twitter Tags -->
    <meta name="twitter:title" :content="$route.meta.title" />
    <meta name="twitter:site" :content="$route.fullPath" />
    <meta name="twitter:description" :content="$route.meta.desc" />
    <meta name="twitter:image" :content="$route.meta.image" />
    <meta name="twitter:card" content="summary_large_image" />

    <!-- Canonical Tag -->
    <link
      rel="canonical"
      :href="`https://www.sgunistats.com${
        $route.fullPath === '/' ? '' : $route.fullPath
      }`"
    />
  </teleport>
</template>
