<template>
  <div class="relative">
    <div
      class="flex items-center justify-between cursor-pointer"
      :class="{
        'hover:text-sky-300': props.headerInView,
        'hover:text-sky-800': !props.headerInView,
        'text-sky-300': props.headerInView && openMenu,
        'text-sky-800': !props.headerInView && openMenu,
      }"
      @click="openMenu = !openMenu"
    >
      <p class="inline-block transition duration-300">More</p>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="w-3 h-3 transition duration-300 sm:w-4 sm:h-4"
        :class="{ 'rotate-90': openMenu }"
        fill="currentColor"
        viewBox="0 0 256 256"
      >
        <rect width="256" height="256" fill="none"></rect>
        <polyline
          points="96 48 176 128 96 208"
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="24"
        ></polyline>
      </svg>
    </div>
    <!-- Navigation Menu Links -->
    <ul
      class="
        absolute
        z-50
        px-2
        mt-1
        overflow-hidden
        text-xs
        font-semibold
        leading-6
        text-center
        transition-all
        duration-200
        rounded-lg
        shadow-lg
        lg:text-sm lg:leading-7 lg:px-4
        max-h-0
        -right-2
        text-blue-gray-900
        bg-blue-gray-100
      "
      :class="{
        'max-h-36 py-2': openMenu,
        'bg-opacity-30': props.headerInView,
        'bg-opacity-70 backdrop-blur': !props.headerInView,
      }"
    >
      <li>
        <router-link
          class="transition duration-200 sm:hover:text-sky-800"
          @click="openMenu = !openMenu"
          :to="{ name: 'poly' }"
          active-class="text-sky-800"
          >Poly</router-link
        >
      </li>
      <li>
        <router-link
          class="transition duration-200 sm:hover:text-sky-800"
          @click="openMenu = !openMenu"
          :to="{ name: 'privacy' }"
          active-class="text-sky-800"
          >Privacy</router-link
        >
      </li>
      <li>
        <router-link
          class="transition duration-200 sm:hover:text-sky-800"
          @click="openMenu = !openMenu"
          :to="{ name: 'terms' }"
          active-class="text-sky-800"
          >Terms</router-link
        >
      </li>
    </ul>
  </div>
</template>

<script setup>
import { ref, toRefs, watch } from "vue";
import { useRoute } from "vue-router";

const props = defineProps({
  headerInView: {
    type: Boolean,
    required: true,
  },
});

const { name: routeName } = toRefs(useRoute());

const openMenu = ref(false);

watch(routeName, () => {
  openMenu.value = false;
});
</script>
