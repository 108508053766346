<template>
  <section class="container px-3 pt-16 pb-16 mx-auto sm:px-0">
    <div class="flex items-center gap-2 mb-2 text-blue-gray-800">
      <h2 class="text-xl font-bold md:text-2xl">
        <span class="hidden sm:inline-block">Most&nbsp;</span>Competitive
        University Courses
      </h2>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="hidden w-8 h-8 md:w-9 md:h-9 sm:inline-block"
        fill="currentColor"
        viewBox="0 0 256 256"
      >
        <rect width="256" height="256" fill="none"></rect>
        <path
          d="M226.52979,56.41016l-96-32a8.00672,8.00672,0,0,0-5.05958,0L29.6239,56.35889l-.00976.00341-.14393.04786c-.02819.00927-.053.02465-.08105.03442a7.91407,7.91407,0,0,0-1.01074.42871c-.03748.019-.07642.03516-.11353.05469a7.97333,7.97333,0,0,0-.93139.58325c-.06543.04688-.129.09522-.19288.144a8.08459,8.08459,0,0,0-.81872.71119c-.0238.02416-.04443.05053-.06787.0747a8.0222,8.0222,0,0,0-.661.783c-.04163.05567-.08472.10986-.12476.16675a8.00177,8.00177,0,0,0-.56714.92993c-.02588.04981-.04809.10083-.073.15112a7.97024,7.97024,0,0,0-.40515.97608c-.01062.03149-.0238.06128-.03405.093a7.95058,7.95058,0,0,0-.26282,1.08544c-.01331.07666-.02405.15308-.035.23A8.02888,8.02888,0,0,0,24,64v80a8,8,0,0,0,16,0V75.09985L73.58521,86.29492a63.9717,63.9717,0,0,0,20.42944,87.89746,95.88087,95.88087,0,0,0-46.48389,37.4375,7.9997,7.9997,0,1,0,13.40235,8.73828,80.023,80.023,0,0,1,134.1333,0,7.99969,7.99969,0,1,0,13.40234-8.73828,95.87941,95.87941,0,0,0-46.4834-37.43725,63.972,63.972,0,0,0,20.42944-87.89771l44.115-14.70508a8.0005,8.0005,0,0,0,0-15.17968ZM128,168A47.99154,47.99154,0,0,1,89.34875,91.54932l36.12146,12.04052a8.00672,8.00672,0,0,0,5.05958,0l36.12146-12.04052A47.99154,47.99154,0,0,1,128,168Z"
        ></path>
      </svg>
    </div>
    <p class="mb-1 text-sm font-medium sm:text-base">
      The courses below had an IGP of
      <strong class="semibold">AAA/A</strong> (~85rp and above).
    </p>
    <p class="mb-8 text-sm leading-5 sm:text-base text-blue-gray-600">
      Click on any of the courses to learn more.
    </p>
    <div class="flex flex-col justify-between lg:flex-row">
      <ul class="text-sm text-blue-900 sm:text-base">
        <li
          class="mb-4 list-disc list-inside"
          v-for="id of topCOPa"
          :key="'cop' + id"
          v-once
        >
          <router-link
            :to="{ path: `/courses/${id}` }"
            class="sm:hover:underline"
          >
            {{ courses[id].major }} ({{ courses[id].university }})
          </router-link>
        </li>
      </ul>
      <ul class="text-sm text-blue-900 sm:text-base">
        <li
          class="mb-4 list-disc list-inside"
          v-for="id of topCOPb"
          :key="'cop' + id"
          v-once
        >
          <router-link
            :to="{ path: `/courses/${id}` }"
            class="sm:hover:underline"
          >
            {{ courses[id].major }} ({{ courses[id].university }})
          </router-link>
        </li>
      </ul>
    </div>
  </section>
</template>

<script setup>
import courses from "@/data/courses.json";
import { topCOP } from "@/data/courseLists.json";
const topCOPa = topCOP.slice(0, 7);
const topCOPb = topCOP.slice(7);
</script>
