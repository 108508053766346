import { createStore } from "vuex";
import cops from "@/data/cops.json";

export default createStore({
  state: {
    scores: {
      subFirstH2: 20,
      subSecondH2: 20,
      subThirdH2: 20,
      subH1: 10,
      subGP: 10,
      subPW: 10,
      subMTL: 0,
    },
    headerInView: true,
  },
  mutations: {
    updateScore({ scores }, { subject, score }) {
      scores[subject] = score;
    },
    updateHeader(state, headerInView) {
      state.headerInView = headerInView;
    },
  },
  actions: {
    updateScore({ commit }, payload) {
      commit("updateScore", payload);
    },
    updateHeader({ commit }, headerInView) {
      commit("updateHeader", headerInView);
    },
  },
  getters: {
    total: ({ scores }) =>
      Object.values(scores).reduce((acc, score) => {
        return acc + score;
      }, 0),
    usesMTL: ({ scores }, { total }) => {
      // Boolean on whether the usage of MTL increases the user's score.
      return total * 0.9 > total - scores.subMTL;
    },
    rankPoints: ({ scores }, { usesMTL, total }) => {
      return usesMTL ? total * 0.9 : total - scores.subMTL;
    },
    eligibleCourses: (_, { rankPoints }) =>
      cops
        .filter((course) => course.cop && rankPoints >= course.cop)
        .map((course) => course.id),
  },
  modules: {},
});
